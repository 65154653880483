import React from 'react'

const style = {
  padding: '110px 0 20px 0',
  textAlign: 'left',
}

const H1 = ({ children }) => <h1 style={style}>{children}</h1>

export default H1
