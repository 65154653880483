import React, { Component } from 'react'
import { Link } from 'gatsby'
import { prependLocale } from '../utils'

import menuOpen from './css/images/mobile_menu_open.png'
import menuClose from './css/images/mobile_menu_close.png'

import enMessages from '../i18n/menu.en.json'
import plMessages from '../i18n/menu.pl.json'
import itMessages from '../i18n/menu.it.json'

const messages = {
  en: enMessages,
  pl: plMessages,
  it: itMessages,
}

class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
      desktop: true,
    }

    this.showMenu = this.showMenu.bind(this)
    this.hideMenu = this.hideMenu.bind(this)
  }

  componentDidMount() {
    this.setState({
      desktop: window.matchMedia('(min-width: 680px)').matches ? true : false,
    })
  }

  showMenu() {
    this.setState({
      showMenu: true,
    })
  }

  hideMenu() {
    this.setState({
      showMenu: false,
    })
  }

  render() {
    return (
      <>
        <a
          onClick={this.showMenu}
          className="show_menu"
          style={{
            display: this.state.desktop
              ? 'none'
              : this.state.showMenu
              ? 'none'
              : 'block',
          }}
          href="#"
        >
          <img src={menuOpen} alt="" title="" />
        </a>
        <a
          onClick={this.hideMenu}
          className="hide_menu"
          style={{
            display: this.state.desktop
              ? 'none'
              : this.state.showMenu
              ? 'block'
              : 'none',
          }}
          href="#"
        >
          <img src={menuClose} alt="" title="" />
        </a>

        <nav
          className="menu"
          style={{
            display:
              this.state.showMenu || this.state.desktop ? 'block' : 'none',
          }}
        >
          <ul id="main_menu">
            <li>
              <Link to={prependLocale(this.props.locale) + '/'}>
                {messages[this.props.locale].home}
              </Link>
            </li>
            <li>
              {/*    TODO CHECK SELECTED LINK      */}
              <Link
                activeClassName="selected"
                to={prependLocale(this.props.locale) + '/wedding-location/'}
              >
                {messages[this.props.locale].wedding_location}
              </Link>
            </li>
            <li>
              <Link
                activeClassName="selected"
                to={prependLocale(this.props.locale) + '/schedule/'}
              >
                {messages[this.props.locale].schedule}
              </Link>
            </li>
            <li>
              <Link
                activeClassName="selected"
                to={prependLocale(this.props.locale) + '/accommodation/'}
              >
                {messages[this.props.locale].accommodation}
              </Link>
            </li>
          </ul>
        </nav>
      </>
    )
  }
}
export default Menu
