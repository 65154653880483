import React from 'react'
import { Link, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { prependLocale } from '../utils'

import enMessages from '../i18n/menu.en.json'
import plMessages from '../i18n/menu.pl.json'
import itMessages from '../i18n/menu.it.json'

const messages = {
  en: enMessages,
  pl: plMessages,
  it: itMessages,
}

const Footer = ({ locale }) => (
  <StaticQuery
    query={graphql`
      query {
        birds: file(relativePath: { eq: "birds_icon.png" }) {
          childImageSharp {
            fixed(width: 118, height: 61) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <div className="footer">
        <div className="full_width_centered">
          <div className="footer_sign">
            <span className="swirl_left_transparent">
              <span className="swirl_right_transparent">
                <Img fixed={data.birds.childImageSharp.fixed} />
              </span>
            </span>
          </div>
          <div className="footer_names">IRMINA &amp; FABRIZIO</div>
          <div className="footer_socials">
            <ul>
              {/* <li><a href="#"><img src="images/social/twitter.png" alt="" title="" /></a></li> */}
              {/* <li><a href="#"><img src="images/social/facebook.png" alt="" title="" /></a></li> */}
              {/* <li><a href="#"><img src="images/social/pinterest.png" alt="" title="" /></a></li> */}
              {/* <li><a href="#"><img src="images/social/vimeo.png" alt="" title="" /></a></li> */}
              {/* <li><a href="#"><img src="images/social/google.png" alt="" title="" /></a></li> */}
              {/* <li><a href="#"><img src="images/social/instagram.png" alt="" title="" /></a></li> */}
            </ul>
          </div>
          <nav className="footer_menu">
            <ul>
              <li>
                <Link
                  to={prependLocale(locale) + '/'}
                  activeClassName="selected"
                >
                  {messages[locale].home}
                </Link>
              </li>
              <li>
                <Link
                  activeClassName="selected"
                  to={prependLocale(locale) + '/wedding-location/'}
                >
                  {messages[locale].wedding_location}
                </Link>
              </li>
              <li>
                <Link
                  activeClassName="selected"
                  to={prependLocale(locale) + '/schedule/'}
                >
                  {messages[locale].schedule}
                </Link>
              </li>
              <li>
                <Link
                  activeClassName="selected"
                  to={prependLocale(locale) + '/accommodation/'}
                >
                  {messages[locale].accommodation}
                </Link>
              </li>
            </ul>
          </nav>
          <div className="footer_copyright">
            Made with love using{' '}
            <a href="https://www.gatsbyjs.org" target="_blank" rel="noopener">
              Gatbsy
            </a>
          </div>
        </div>
      </div>
    )}
  />
)

export default Footer
