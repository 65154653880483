import React from 'react'
import { graphql } from 'gatsby'

import Img from 'gatsby-image'

import enMessages from '../i18n/wedding-location.en.json'
import plMessages from '../i18n/wedding-location.pl.json'
import itMessages from '../i18n/wedding-location.it.json'
import Layout from '../components/layout'
import SingleHeader from '../components/single-header'

import Slider from 'react-slick'
import Footer from '../components/footer'

import '../../node_modules/slick-carousel/slick/slick.css'
import '../../node_modules/slick-carousel/slick/slick-theme.css'
import './wedding-location.css'

const messages = {
  en: enMessages,
  pl: plMessages,
  it: itMessages,
}

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  initialSlide: 0,
  lazyLoad: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
}

const WeddingLocationPage = ({ pageContext: { locale }, data }) => (
  <Layout locale={locale}>
    <SingleHeader
      locale={locale}
      title={messages[locale].title}
      img={data.locationImage}
    />

    <div className="page_content">
      <div className="full_width_centered">
        <p dangerouslySetInnerHTML={{ __html: messages[locale].description }} />
        <p dangerouslySetInnerHTML={{ __html: messages[locale].address }} />

        <Slider {...settings}>
          {data.slides.edges.map((el, index) => (
            <div key={index}>
              <Img
                style={{ margin: '0 12px' }}
                fluid={el.node.childImageSharp.fluid}
              />
            </div>
          ))}
        </Slider>

        <br />
        <br />
        <br />
        <br />

        <h2>{messages[locale].location_on_map}</h2>

        <div id="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28055.328495980262!2d17.65982094502105!3d51.38814606380063!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x360508fb109dfe42!2sDom+weselno-bankietowy+%22Kraszowjanka%22!5e0!3m2!1sen!2sro!4v1542642943188"
            width="100%"
            height="450"
            frameBorder="0"
            style={{ saturation: '-90', border: 0 }}
            allowFullScreen
          />
        </div>

        <br />
        <br />
        <br />

        <h2>{messages[locale].how_to_get_there}</h2>

        <p>{messages[locale].how_to_get_there_description}</p>
      </div>
    </div>
    <Footer locale={locale} />
  </Layout>
)

export default WeddingLocationPage

export const pageQuery = graphql`
  query {
    locationImage: file(relativePath: { eq: "location/location.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 369, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slides: allFile(filter: { relativePath: { glob: "location/slide*" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 341, maxHeight: 245, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
