import React from 'react'
import Menu from './menu'
import H1 from './h1'
import Img from 'gatsby-image'
import './single-header.css'

const SingleHeader = ({ locale, img, title }) => (
  <>
    <Menu locale={locale} />
    <div className="page_header_photo page_header_trans single-header__layer">
      <Img
        className="slider_img single-header__image"
        fluid={img.childImageSharp.fluid}
      />
      <div className="page_header_title">
        <H1>{title}</H1>
      </div>
    </div>
  </>
)

export default SingleHeader
